  import { createContext, useMemo, useState } from "react";
  import Backdrop from "@mui/material/Backdrop";
  import CircularProgress from "@mui/material/CircularProgress";

  const LoadingContext = createContext({
    loading: false,
    setLoading: null,
  });

  export function LoadingProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const value = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);
    return (
      <LoadingContext.Provider value={value}>
        {loading && (
          <Backdrop sx={{ zIndex: 9999 }} open={loading}>
            <CircularProgress color="primary" />
          </Backdrop>
        )}
        {children}
      </LoadingContext.Provider>
    );
  }

  export default LoadingContext;
