import React from "react";
import SessionRoutes from "./Views/Session/SessionRoutes";
import ApplicationRoutes from "./Views/Application/ApplicationRoutes";
import ScanQr from "./Views/Application/ScanQr"

const routes = [
  ...ApplicationRoutes,
  ...SessionRoutes,
  {
    path: "/",
    element: <ScanQr/>,
  },
  { path: "403", element: <div>Permission Denied</div> },
  { path: "*", element: <div>Not Found</div> },
];

export default routes;
