export const PrimaryLight = "#B8DDEF";
export const Primary = "#35A0FB";
export const PrimaryDark = "#128FC9";
export const PrimaryBG = "#ffffff";

export const PrimaryContrast = "#ffffff";
export const Error = "#F2556D";
export const Success = "#41D6AE";
export const Warning = "#FFCE72";

export const BaseFontSize = 14;
export const MobileBaseFontSize = 12;

export const GreyDark = "#dbdbdb";
export const Grey = "#D8E1EF";
export const BoxShadow = `0 0.5rem 1rem rgba(${Primary}, 0.15) !default`;
