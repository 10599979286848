import React from "react";
import theme from "../Theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import useSession from "src/Hooks/useSession";
import { useMediaQuery } from "@mui/material";

function Provider({ children }) {
  const {
    sessionDetails: { restaurant },
  } = useSession();
  const isMobile = useMediaQuery("@media (max-width:899.95px)");
  const responsiveTheme = responsiveFontSizes(
    theme({
      base_colour: restaurant?.theme?.base_colour?.trim(),
      isMobile: isMobile,
    })
  );
  return (
    <ThemeProvider theme={responsiveTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default Provider;
