import React, { useEffect, createContext, useReducer } from "react";
import MenuSkelton from "src/Components/Skelton/MenuSkelton";

const initialState = {
  isInitialised: false,
  sessionDetails: {},
  cart_id: null,
  cartAddedAddons: [],
  notes: "",
  item_notes: {},
  table_number: "",
  is_room_order: false,
  is_table_order: false,
  home_url: "",
  showFilterDialog: false,
  selectedFilters: {},
};

const setSession = (
  data,
  home_url,
  tableNo = "",
  isRoomOrder = false,
  isTableOrder = false,
  notes = ""
) => {
  window.sessionStorage.setItem("kayana_session_details", JSON.stringify(data));
  window.sessionStorage.setItem("table_number", tableNo);
  window.sessionStorage.setItem("is_room_order", isRoomOrder);
  window.sessionStorage.setItem("is_table_order", isTableOrder);
  window.sessionStorage.setItem("home_url", home_url);
  window.sessionStorage.setItem("notes", notes);
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        isInitialised: true,
      };
    case "SET_SESSION": {
      const {
        table_number,
        is_room_order,
        is_table_order,
        home_url,
        ...sessionDetails
      } = action.payload;
      setSession(
        sessionDetails,
        home_url || state?.home_url,
        table_number || "",
        is_room_order || false,
        is_table_order || false,
        ""
      );
      return {
        ...state,
        sessionDetails: sessionDetails,
        table_number: table_number || "",
        is_room_order: !!is_room_order,
        is_table_order: !!is_table_order,
        home_url: home_url || state?.home_url,
        notes: "",
      };
    }

    case "SET_CART_ID": {
      const { cart_id } = action.payload;
      window.sessionStorage.setItem("kayana_cart_id", cart_id);
      return {
        ...state,
        cart_id: cart_id,
      };
    }

    case "SET_NOTES": {
      const { notes } = action.payload;
      window.sessionStorage.setItem("notes", notes);
      return {
        ...state,
        notes: notes,
      };
    }

    case "REMOVE_NOTES": {
      window.sessionStorage.removeItem("notes");
      return {
        ...state,
        notes: "",
      };
    }

    case "SET_ITEM_NOTES": {
      const { item_notes } = action.payload;
      window.sessionStorage.setItem("item_notes", JSON.stringify(item_notes));
      return {
        ...state,
        item_notes: item_notes,
      };
    }

    case "REMOVE_ITEM_NOTES": {
      window.sessionStorage.removeItem("item_notes");
      return {
        ...state,
        item_notes: {},
      };
    }

    case "SET_TABLE_NUMBER": {
      const { table_number } = action.payload;
      window.sessionStorage.setItem("table_number", table_number);
      return {
        ...state,
        table_number: table_number,
      };
    }
    case "REMOVE_CART_ID": {
      window.sessionStorage.removeItem("kayana_cart_id");
      return {
        ...state,
        cart_id: null,
      };
    }
    case "ADD_CART_ADDED_ADDONS": {
      const { cart_added_addons } = action.payload;
      window.sessionStorage.setItem(
        "kayana_cart_added_addons",
        JSON.stringify([...state.cartAddedAddons, ...cart_added_addons])
      );
      return {
        ...state,
        cartAddedAddons: [...state.cartAddedAddons, ...cart_added_addons],
      };
    }
    case "REMOVE_CART_ADDED_ADDONS": {
      window.sessionStorage.removeItem("kayana_cart_added_addons");
      return {
        ...state,
        cartAddedAddons: [],
      };
    }

    case "SHOW_FILTER_DIALOG": {
      return {
        ...state,
        showFilterDialog: true,
      };
    }

    case "CLOSE_FILTER_DIALOG": {
      return {
        ...state,
        showFilterDialog: false,
      };
    }

    case "SET_SELECTED_FILTERS": {
      const { selectedFilters } = action.payload;
      window.sessionStorage.setItem(
        "selectedFilters",
        JSON.stringify(selectedFilters)
      );
      return {
        ...state,
        selectedFilters: selectedFilters,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const SessionContext = createContext({
  ...initialState,
});

export const SessionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const loadSessionDetails = () => {
      const ses_det = window.sessionStorage.getItem("kayana_session_details");
      const table_number = window.sessionStorage.getItem("table_number");
      const is_room_order = window.sessionStorage.getItem("is_room_order");
      const is_table_order = window.sessionStorage.getItem("is_table_order");
      const home_url = window.sessionStorage.getItem("home_url");

      if (ses_det) {
        dispatch({
          type: "SET_SESSION",
          payload: {
            ...JSON.parse(ses_det),
            table_number: table_number || "",
            is_room_order: is_room_order === "true",
            is_table_order: is_table_order === "true",
            home_url: home_url || "",
          },
        });
      }
    };

    const loadCartDetails = () => {
      const cart_id = window.sessionStorage.getItem("kayana_cart_id");
      const cart_added_addons = window.sessionStorage.getItem(
        "kayana_cart_added_addons"
      );
      const item_notes = window.sessionStorage.getItem("item_notes");
      if (cart_id) {
        dispatch({
          type: "SET_CART_ID",
          payload: { cart_id },
        });

        if (cart_added_addons) {
          dispatch({
            type: "ADD_CART_ADDED_ADDONS",
            payload: { cart_added_addons: JSON.parse(cart_added_addons) },
          });
        }
        if (item_notes) {
          dispatch({
            type: "SET_ITEM_NOTES",
            payload: { item_notes: JSON.parse(item_notes) },
          });
        }
      }
    };

    const loadSelectedFilters = () => {
      const selectedFilters = window.sessionStorage.getItem("selectedFilters");

      if (selectedFilters) {
        dispatch({
          type: "SET_SELECTED_FILTERS",
          payload: { selectedFilters: JSON.parse(selectedFilters) },
        });
      }
    };

    const initializeSession = () => {
      dispatch({ type: "INIT" });
    };

    try {
      loadSessionDetails();
      loadSelectedFilters();
      loadCartDetails();
      initializeSession();
    } catch (err) {}
  }, []);

  return state?.isInitialised ? (
    <SessionContext.Provider value={{ ...state, dispatch }}>
      {children}
    </SessionContext.Provider>
  ) : (
    <MenuSkelton />
  );
};

export default SessionContext;
