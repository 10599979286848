import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function Signin() {
  const navigate = useNavigate();
  return (
    <Box height="100vh" width="100vw" bgcolor="#9E9CA4" pt="10%">
      <Card sx={{ maxWidth: { xs: "95%", md: "35%" }, mx: "auto" }}>
        <CardContent>
          <Typography variant="h5">Sign up / Log in</Typography>
          <Typography>Sign in for our new offers/Newsletters</Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6}>
              <TextField fullWidth label="First Name" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth label="Last Name" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Enter Email address / Phone Number" />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" color="primary">
                Next
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => navigate("/location")}
                fullWidth
                variant="text"
                color="primary"
              >
                Use guest login
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Signin;
