import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import QrReader from "react-web-qr-reader";

function ScanQr() {
  const [showScanner, setShowScanner] = useState(false);
  return (
    <Stack padding={2} alignItems="center">
      <Typography mb={2} variant="h4">
        Scan Qr code
      </Typography>
      <Button sx={{ mb: 2 }} onClick={() => setShowScanner(!showScanner)}>
        {showScanner ? "Cancel" : "Click to Scan"}
      </Button>
      {showScanner && (
        <QrReader
          delay={100}
          style={{
            height: 320,
            width: 320,
          }}
          onError={(error) => console.log("error", error)}
          onScan={(res) => {
            if (res && res?.data) {
              setShowScanner(false);
              window.location.href = res?.data;
            }
          }}
        />
      )}
    </Stack>
  );
}

export default ScanQr;
