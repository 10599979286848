import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@mui/material";

export default function MenuSkelton() {
  return (
    <Grid
      container
      height="100vh"
      width={"calc(100% - 8px)"}
      spacing={{ xs: 1, md: 2 }}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((i) => (
        <Grid item key={i}>
          <Skeleton
            variant="rounded"
            sx={{ minHeight: "250px", width: { xs: "46vw", md: "17rem" } }}
            height="100%"
          />
        </Grid>
      ))}
    </Grid>
  );
}
