import React, { lazy, Suspense } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const SelectOrderType = lazy(() => import("./SelectOrderType"));
const ItemList = lazy(() => import("./ItemList"));
const SelectLocation = lazy(() => import("./SelectLocation"));
const AddEditItem = lazy(() => import("./AddEditItem"));
const OrderPlaced = lazy(() => import("./OrderPlaced"));
const CartBase = lazy(() => import("./Components/CartBase"));
const ItemListBase = lazy(() => import("./Components/ItemListBase"));
const PaymentDetails = lazy(() => import("./PaymentDetails"));
const OrderReceipt = lazy(() => import("./OrderReceipt"));
const CartDetails = lazy(() => import("./CartDetails"));
const InactivePlan = lazy(() => import("./InactivePlan"));
const SelectVendor = lazy(() => import("./SelectVendor"));
const NoMenu = lazy(()=>import("./NoMenu"));
const PropertyClosed = lazy(()=>import("./PropertyClosed"));

const RenderLoader = React.memo(() => (
  <Backdrop sx={{ zIndex: 9999 }} open={true}>
    <CircularProgress color="primary" />
  </Backdrop>
));

const ApplicationRoutes = [
  {
    path: "/menu",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <ItemListBase />
      </Suspense>
    ),

    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<RenderLoader />}>
            <ItemList />
          </Suspense>
        ),
      },
      {
        path: "addItem",
        element: (
          <Suspense fallback={<RenderLoader />}>
            <AddEditItem />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/cart",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <CartBase />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<RenderLoader />}>
            <CartDetails />
          </Suspense>
        ),
      },
      {
        path: "updateItem",
        element: (
          <Suspense fallback={<RenderLoader />}>
            <AddEditItem />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/payment/:orderId",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <PaymentDetails />
      </Suspense>
    ),
  },
  {
    path: "/location",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <SelectLocation />
      </Suspense>
    ),
  },
  {
    path: "/property/:property_id",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <SelectOrderType />
      </Suspense>
    ),
  },
  {
    path: "/vendors/:property_id/",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <SelectVendor />
      </Suspense>
    ),
  },
  {
    path: "/property/:property_id/ordersource/:order_type?",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <SelectOrderType />
      </Suspense>
    ),
  },
  {
    path: "/property/:property_id/ordertype/:order_type?",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <SelectOrderType />
      </Suspense>
    ),
  },
  {
    path: "/property/:property_id/room/:room_number?",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <SelectOrderType />
      </Suspense>
    ),
  },
  {
    path: "/property/:property_id/table/:table_number?",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <SelectOrderType />
      </Suspense>
    ),
  },
  {
    path: "/property/:property_id",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <SelectOrderType />
      </Suspense>
    ),
  },
  {
    path: "/order_placed/:orderId/",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <OrderPlaced />
      </Suspense>
    ),
  },
  {
    path: "/receipt/:orderId/",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <OrderReceipt />
      </Suspense>
    ),
  },
  {
    path: "/inactive-plan/",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <InactivePlan />
      </Suspense>
    ),
  },
  {
    path: "/no-menu/",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <NoMenu />
      </Suspense>
    ),
  },
  {
    path: "/property-closed/:property_id",
    element: (
      <Suspense fallback={<RenderLoader />}>
        <PropertyClosed />
      </Suspense>
    ),
  },
];

export default ApplicationRoutes;
